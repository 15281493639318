import React, { useState } from 'react';
import { addIssue } from '../../../firebase';
import styles from './IssueForm.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

export const IssueForm = ({ sessionId }) => {
  const [issueNumber, setIssueNumber] = useState('');
  const [issue, setIssue] = useState('');

  const handleAddIssue = () => {
    const concatIssue = issueNumber + ': ' + issue;
    addIssue(sessionId, concatIssue).then(() => {
      setIssue('');
      setIssueNumber('')
    })
  }

  return (
    <div>
      <div>
        <label>Add an issue:</label></div>
      <div className={styles.issueFormContainer}>
        <div>        
          <input placeholder="Issue #" style={{width: "100px", marginRight: 10}} value={issueNumber} onChange={e => setIssueNumber(e.target.value)} />
        </div>
        <div>        
          <input placeholder="Description" style={{width: "500px", marginRight: 10}} value={issue} onChange={e => setIssue(e.target.value)} />
        </div>
        <div>        
          <button onClick={handleAddIssue}>
            <FontAwesomeIcon icon={faPlus}/>
            &nbsp;Add issue
          </button>
        </div>
      </div>
    </div>
  )
}
