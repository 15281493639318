import React, { useState, useEffect } from 'react'
import { collection, query, onSnapshot } from 'firebase/firestore'
import { db, setCurrentIssue, resetVotes } from '../../../firebase'
import styles from './Issues.module.css';

export const Issues = ({ sessionId, isAdmin, currentIssueId }) => {
  const [issues, setIssues] = useState([])

  const getIssues = async () => {
    const q = query(collection(db, 'sessions/' + sessionId + '/issues'))

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const tempIssueList = []
      querySnapshot.forEach((doc) => {
        tempIssueList.push({ id: doc.id, data: doc.data() })
      })
      setIssues(tempIssueList)
    })
  }

  useEffect(() => {
    getIssues()
  }, [issues.length])

  const handleSetIssue = (issueId, description) => {
    if (isAdmin) {
      console.log(issueId);
      setCurrentIssue(sessionId, issueId, description)
      resetVotes(sessionId);
    }
  }

  const issueItems = issues.map((issue) => 
  <tr className={styles.issueItem} style={{cursor: isAdmin ? 'pointer' : 'default'}} key={issue.id} onClick={() => handleSetIssue(issue.id, issue.data.description)}>
    <td className={currentIssueId === issue.id ? styles.activeIssue : ''}>{issue.data.description}</td>
  </tr>
  )

  return (
    <div>
      <label>List of issues:</label>

      <table>
        <tbody>
        {issueItems}
        </tbody>
      </table>
    </div>
  )
}