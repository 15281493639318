import React, { useContext } from 'react'
import styles from './Players.module.css'
import { Card } from '../../atoms/card';
import AppContext from '../../../AppContext';
import { kickPlayer, makePlayerObserver } from '../../../firebase';

export const Players = ({ players, votes, sessionId }) => {
  const [user, setUser] = useContext(AppContext)

  const playerItems = players.map((currentPlayer, index) => {
    const playerVote = votes.find(({ player }) => player === currentPlayer.id);
    const showCards = players.length === votes.length;

    return (
        <div className={styles.player} key={index}>
        <div>{user.id === currentPlayer.id ? currentPlayer.data.name + ' (You)' : currentPlayer.data.name}</div>

        {playerVote &&
          <Card content={showCards ? playerVote.vote: "?"} canClick={false} />
        }

        {!playerVote &&
          <Card 
            emoji={currentPlayer.data.pic} 
            canClick={false} 
            isAdmin={user.isAdmin} 
            kickHandler={() => kickPlayer(sessionId, currentPlayer.id)} 
            observeHandler={() => makePlayerObserver(sessionId, currentPlayer.id)} 
          />
        }
      </div>
      )
    }
  )

  return (
    <div className={styles.playerList}>
      {playerItems}
    </div>
  )
}
