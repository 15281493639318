import { useState } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom'
import { Home } from './components/pages/home'
import { Session } from './components/pages/session'
import AppContext from './AppContext'
import styles from './App.module.css'

function App () {
  const [user, setUser] = useState()

  return (
    <AppContext.Provider value={[user, setUser]}>
      <div className={styles.appContainer}>
        <div className={styles.appHeader}>
          <div className={styles.appName}>Cadiqo Scrum Poker</div>
        </div>
        <Router>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='session/:sessionId' element={<Session />} />
          </Routes>
        </Router>
      </div>
    </AppContext.Provider>
  )
}

export default App
