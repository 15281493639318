import React from 'react'
import styles from './SectionHeader.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

export const SectionHeader = ({ title }) => {
  return (
    <div className={styles.sectionHeader}>
        < FontAwesomeIcon icon={faChevronDown} /> {title}
    </div>
  )
}
