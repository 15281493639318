import React, { useContext } from 'react'
import { createSession, joinGame } from '../../../firebase'
import { useNavigate } from 'react-router-dom'
import AppContext from '../../../AppContext';
import styles from './Home.module.css';
import { UserForm } from '../../organisms/userForm';
import { SectionHeader } from '../../atoms/SectionHeader';
import { getRandomEmoji } from '../../../common';

export const Home = () => {
  const navigate = useNavigate()
  const [user, setUser] = useContext(AppContext)

  const handleCreateSession = (yourName) => {
    createSession(yourName).then((sessionId) => {
      const emoji = getRandomEmoji([]);
      joinGame(sessionId, yourName, emoji, true).then((userId) => {
        setUser({ id: userId, name: yourName, isAdmin: true })
        navigate('/session/' + sessionId)
      })
    })
  }
  return (
    <div className={styles.homeContainer}>
      <SectionHeader title="Session" />
      <UserForm buttonLabel='Create session' buttonAction={handleCreateSession} />
    </div>
  )
}
