import React, { useState, useEffect } from 'react'
import { vote, resetVotes } from '../../../firebase'
import { Card } from '../../atoms/card';
import { Fibonacci } from '../../../common';
import styles from './Vote.module.css';
import { SectionHeader } from '../../atoms/SectionHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRotate } from '@fortawesome/free-solid-svg-icons'

export const Vote = ({ sessionId, player, currentIssue, votes }) => {
  const [hasVoted, setHasVoted] = useState(false);
  const [yourVote, setYourVote] = useState('');

  const handleVote = (value) => {
    vote(sessionId, player.id, value);
    setYourVote(value);
    setHasVoted(true);
  }

  const handleResetVotes = () => {
    resetVotes(sessionId);
    setHasVoted(false);
  }

  const fibonacciItems = Fibonacci.map((value, index) =>
    <Card key={index} clickHandler={() => handleVote(value)} content={value} canClick={true} size="small"/>
  )

  useEffect(() => {
    setHasVoted(false);
  }, [currentIssue])

  useEffect(() => {
    if (votes.length === 0) {
      setHasVoted(false);
    }
  }, [votes])

  return (
    <div>
      <SectionHeader title="Vote" />

      <div className={styles.issueContainer}>
        <div>
          <label>Currently voting on:</label>
          <div>{currentIssue}</div>
        </div>
        { player.isAdmin &&
        <div>
          <button onClick={handleResetVotes}>
            <FontAwesomeIcon icon={faRotate}/>
            &nbsp;Reset votes
          </button>
        </div>
        }
      </div>

      {hasVoted && 
      <div className={styles.votedMessage}>You voted {yourVote}!</div>
      }

      {!hasVoted &&
        <div className={styles.cardContainer}>
          {fibonacciItems}
        </div>
      }
    </div>
  )
}
