import { initializeApp } from 'firebase/app'
// import { getDatabase } from "firebase/database";
import { getFirestore, doc, collection, addDoc, updateDoc, getDocs, deleteDoc, limit, where, query } from 'firebase/firestore'

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyCFV3DzfeculnGibUrrzdHnZBEroj2Olj0',
  authDomain: 'cadiqo-poker.firebaseapp.com',
  projectId: 'cadiqo-poker',
  storageBucket: 'cadiqo-poker.appspot.com',
  messagingSenderId: '980086627245',
  appId: '1:980086627245:web:33ce3854d4005289ad1f24',
  databaseURL: 'https://cadiqo-poker-default-rtdb.asia-southeast1.firebasedatabase.app'
}

const app = initializeApp(firebaseConfig)
// export const db = getDatabase(app);
export const db = getFirestore(app)

export const createSession = async (creator) => {
  const docRef = await addDoc(collection(db, 'sessions/'), {
    creator
  })

  return docRef.id
}

export const joinGame = async (sessionId, playerName, emoji, isAdmin) => {
  const playerRef = await addDoc(collection(db, 'sessions/' + sessionId + '/players'), {
    name: playerName,
    pic: emoji,
    isAdmin: isAdmin
  })

  return playerRef.id
}

export const addIssue = async (sessionId, description) => {
  const issueRef = await addDoc(collection(db, 'sessions/' + sessionId + '/issues'), {
    description
  })

  return issueRef.id
}

export const setCurrentIssue = async (sessionId, issueId, description) => {
  const sessionRef = doc(db, 'sessions/' + sessionId)

  await updateDoc(sessionRef, {
    currentIssue: issueId,
    currentIssueDesc: description
  })
}

export const vote = async (sessionId, player, value) => {
  const votesRef = await addDoc(collection(db, 'sessions/' + sessionId + '/votes'), {
    player,
    vote: value
  })

  return votesRef.id
}

export const deleteVote = async (sessionId, voteId) => {
  await deleteDoc(doc(db, "sessions", sessionId, "votes", voteId));
}

export const resetVotes = async (sessionId) => {
  const querySnapshot = await getDocs(collection(db, "sessions/" + sessionId + "/votes"));

  querySnapshot.forEach((doc) => {
    deleteVote(sessionId, doc.id)
  });
}

export const kickPlayer = async (sessionId, playerId) => {
  await deleteDoc(doc(db, "sessions", sessionId, "players", playerId));
}

export const makePlayerObserver = async (sessionId, playerId) => {
  const playerRef = doc(db, 'sessions/' + sessionId + '/players/' + playerId)

  await updateDoc(playerRef, {
    observer: true
  })
}