import React, { useState } from 'react'
import styles from './UserForm.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightToBracket } from '@fortawesome/free-solid-svg-icons'

export const UserForm = ({ buttonLabel, buttonAction }) => {
  const [yourName, setYourName] = useState('')

  const handleButtonClick = () => {
    buttonAction(yourName)
  }
  return (
    <div className={styles.userFormContainer}>
      <div>
		    <label>Your name:</label>
      </div>
      <div>
        <input style={{ width: '300px' }} value={yourName} onChange={e => setYourName(e.target.value)} />
      </div>
      <div>
        <button className={styles.joinButton} onClick={handleButtonClick}>
          <FontAwesomeIcon icon={faArrowRightToBracket} />
          &nbsp;{buttonLabel}
        </button>
      </div>
    </div>
  )
}
