import React from 'react'
import styles from './Card.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserXmark, faBinoculars } from '@fortawesome/free-solid-svg-icons'

export const Card = ({emoji, content, clickHandler, canClick, size, kickHandler, observeHandler, isAdmin = false}) => {
  const lineHeight = size === 'small' ? '75px' : '150px';

  const renderEmoji = (emojiCode) => {
    return {__html: '&#' + emojiCode + ';'};
  } 

  const renderContent = (content) => {
    if (content === "infinite") return <span>&#8734;</span>
    else return <span>{content}</span>
  }

  const dynamicStyles = {
    card: {
      cursor: canClick ? "pointer" : "default",
      lineHeight: emoji ? "" : lineHeight
    }
  }

  return (
    <div>
      <div className={size === 'small' ? styles.cardSmall : styles.card} onClick={clickHandler} style={dynamicStyles.card}>
        { emoji &&
          <div className={styles.cardContainer}>
            <div className={styles.emoji} dangerouslySetInnerHTML={renderEmoji(emoji)}></div>
            { isAdmin &&
            <div className={styles.adminCommands}>
              <FontAwesomeIcon icon={faUserXmark} onClick={kickHandler} title="Kick user"/>
              <FontAwesomeIcon icon={faBinoculars} onClick={observeHandler} title="Make user observer"/>
            </div>
            }
          </div>
        }

        { content &&
          renderContent(content)
        }
      </div>
    </div>
  )
}