import React, { useState, useEffect, useContext } from 'react'
import QRCode from 'react-qr-code'
import {
  BrowserRouter as Router,
  useParams
} from 'react-router-dom'

import { collection, query, doc, onSnapshot } from 'firebase/firestore'
import { joinGame, db } from '../../../firebase'

import AppContext from '../../../AppContext'
import styles from './Session.module.css'
import { IssueForm } from '../../organisms/issueForm'
import { Issues } from '../../organisms/issues'
import { Vote } from '../../organisms/vote'
import { UserForm } from '../../organisms/userForm'
import { Players } from '../../organisms/players'
import { SectionHeader } from '../../atoms/SectionHeader'
import { getRandomEmoji, Emoji } from '../../../common'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'

export const Session = () => {
  const { sessionId } = useParams()
  const [user, setUser] = useContext(AppContext)

  const [players, setPlayers] = useState([])
  const [usedEmojis, setUsedEmojis] = useState([])
  const [votes, setVotes] = useState([])
  const [yourName, setYourName] = useState('')
  const [currentIssueId, setCurrentIssueId] = useState()
  const [currentIssueDesc, setCurrentIssueDesc] = useState()

  const getPlayers = async () => {
    const q = query(collection(db, 'sessions/' + sessionId + '/players'))

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const tempPlayerList = []
      const tempEmojis = []
      querySnapshot.forEach((doc) => {
        tempPlayerList.push({ id: doc.id, data: doc.data() })
        tempEmojis.push(doc.data().pic);
      })
      setPlayers(tempPlayerList)
      setUsedEmojis(tempEmojis)
    })
  }

  const getVotes = async () => {
    const q = query(collection(db, 'sessions/' + sessionId + '/votes'))

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const tempVoteList = []
      querySnapshot.forEach((doc) => {
        tempVoteList.push(doc.data())
      })
      setVotes(tempVoteList)
    })
  }

  const getCurrentIssue = async () => {
    const unsub = onSnapshot(doc(db, 'sessions/' + sessionId), (doc) => {
      setCurrentIssueId(doc.data().currentIssue)
      setCurrentIssueDesc(doc.data().currentIssueDesc)
    })
  }

  const handleJoinGame = (yourName) => {
    let emoji = getRandomEmoji(usedEmojis);

    joinGame(sessionId, yourName, emoji, false).then((userId) => {
      setUser({ id: userId, name: yourName })
    })
  }

  const handleCopyLink = () => {
    const copyText = 'https://cadiqo-poker.web.app/session/' + sessionId;

    navigator.clipboard.writeText(copyText);
  }

  useEffect(() => {
    getPlayers()
    getVotes()
    getCurrentIssue()
  }, [players.length])

  return (
    <div className={styles.sessionContainer}>
      {user && 
      <div className={styles.flexContainer}>
        <div className={styles.leftContainer}>
          <div>
            <div>Hey {user.name} - you're in the game!</div>
          </div>

          <SectionHeader title="Players" />
          {players &&
            <Players players={players} votes={votes} sessionId={sessionId} />
          }

          {currentIssueDesc &&
            <div>
              <Vote sessionId={sessionId} player={user} currentIssue={currentIssueDesc} votes={votes}/>
            </div>
          }

          {!currentIssueDesc &&
            <div>
              Select an issue to start voting!
            </div>
          }
        </div>
        <div className={styles.rightContainer}>
          <SectionHeader title="Invite players" />
          <label>Share this link:</label>
          <div>
            <span style={{paddingRight: '10px'}}>https://cadiqo-poker.web.app/session/{sessionId}</span>
            <button onClick={handleCopyLink}>
              <FontAwesomeIcon icon={faCopy}/>
              &nbsp;Copy
            </button>
          </div>

          <SectionHeader title="Issues" />

          {user.isAdmin &&
          <IssueForm sessionId={sessionId} />
          }

          <Issues sessionId={sessionId} isAdmin={user.isAdmin} currentIssueId={currentIssueId}/>
        </div>
      </div>
      }

      {!user &&
        <div className={styles.joinContainer}>
          <SectionHeader title="Join a game" />
          <UserForm buttonLabel='Join game' buttonAction={handleJoinGame} />

          <SectionHeader title="Join on mobile" />
          <div className={styles.qrCode }>
              <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={'https://cadiqo-poker.web.app/session/' + sessionId}
                viewBox={`0 0 256 256`}
              />
            </div>
        </div>
      }
    </div>
  )
}
